const links = [
  {
    label: 'Github',
    url: 'https://github.com/aluiziodeveloper',
  },
  {
    label: 'Youtube',
    url: 'https://www.youtube.com/jorgealuizio',
  },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/in/jorgealuizio',
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/jorge_aluizio',
  },
  {
    label: 'DevTo',
    url: 'https://letsgoahead.com.br',
  },
]

export default links
