import styled from 'styled-components'
import media from 'styled-media-query'

export const AvatarWrapper = styled.div`
  .gatsby-image-wrapper {
    height: 3rem;
    width: 7.8rem;
    margin-left: 4rem;  

    ${media.lessThan('large')`
      height: 2rem;
      width: 5.2rem;
      margin: 10px 0;
    `}
  }
`
