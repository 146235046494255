import styled from 'styled-components'
import media from 'styled-media-query'

export const NavBarNav = styled.nav`
  ${media.lessThan('large')`
    display: none;
  `}
`

export const NavBarUl = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin-right: 4rem;
`

export const NavBarLi = styled.li`
  padding: 0;

  & + li {
    margin-left: 32px;
  }
`

export const NavBarLink = styled.a`
  color: #d9d9d9;
  text-decoration: none;
  &:hover {
    color: #ffdb77;
  }
`

export const NavBarIcon = styled.div`
  fill: #d9d9d9;
  width: 30px;
  height: 30px;
`
