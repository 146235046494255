import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const MenuNav = styled.nav`
  ${media.lessThan('large')`
    padding-bottom: 15px;
  `}
`

export const MenuUl = styled.ul`
  font-size: 1.2rem;
  font-weight: 300;
  align-items: center;
  display: flex;
  list-style: none;

  ${media.lessThan('large')`
    font-size: 1rem;
  `}

  ${media.lessThan('small')`
    font-size: 0.8rem;
  `}
`

export const MenuLi = styled.li`
  padding: 0;

  & + li {
    margin-left: 32px;
  }

  .active {
    color: #ffdb77;
  }
`

export const MenuLink = styled(Link)`
  color: #d9d9d9;
  text-decoration: none;
  transition: color 0.5s;

  &:hover {
    color: #ffdb77;
  }
`
