import React from 'react'
import { Link } from 'gatsby'

import Avatar from '../Avatar';
import Menu from '../Menu'
import SocialLinks from '../SocialLinks'
import { Container } from './styles'

const Header = () => {
  return (
    <Container>
      <Link to="/">
        <Avatar />
      </Link>
      <Menu />
      <SocialLinks />
    </Container>
  );
}

export default Header