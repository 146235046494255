import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.header`
  position: fixed;
  background: #121214;
  display: flex;
  align-items: center;
  height: 5.5rem;
  width: 100%;
  padding: 0 15px 0;
  justify-content: space-between;
  border-bottom: 1px solid #29292E;

  ${media.lessThan('large')`
    height: 6.5rem;
    flex-direction: column;
  `}
`
