import React from 'react'
import {Link} from 'gatsby'

import { Container } from './styles'

const Footer = () => {
  const year = new Date();

  return (
    <Container>
      <p>{`© Aluizio Developer - ${year.getFullYear()} - Todos os direitos reservados`}</p>
      <Link to="/">Política de privacidade | Termos de uso</Link>
    </Container>
  );
}

export default Footer