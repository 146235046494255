import React from 'react'

import {
  MenuNav,
  MenuLi,
  MenuLink,
  MenuUl,
} from './styles'
import links from './content'

const Menu = () => (
  <MenuNav>
    <MenuUl>
      {links.map((link, i) => {
        return (
          <MenuLi key={i}>
            <MenuLink to={link.url} activeClassName="active">
              {link.label}
            </MenuLink>
          </MenuLi>
        )
      })}
    </MenuUl>
  </MenuNav>
)

export default Menu
