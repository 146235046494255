import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.footer`
  background: #121214;
  position: fixed;
  bottom:0;
  width:100%;
  border-top: 1px solid #09090A;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;

  ${media.lessThan('large')`
    display: none;
  `}

  p {
    font-size: 0.7rem;
    color: #d9d9d9;
  }

  a {
    color: #d9d9d9;
    text-decoration: none;
    font-size: 0.7rem;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
`
