import React from 'react'

import {
  NavBarNav,
  NavBarIcon,
  NavBarLi,
  NavBarLink,
  NavBarUl,
} from './styles'
import Icons from './Icons'
import links from './content'

const SocialLinks = () => (
  <NavBarNav>
    <NavBarUl>
      {links.map((link, i) => {
        const Icon = Icons[link.label]

        return (
          <NavBarLi key={i}>
            <NavBarLink
              href={link.url} title={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              <NavBarIcon>
                <Icon />
              </NavBarIcon>
            </NavBarLink>
          </NavBarLi>
        )
      })}
    </NavBarUl>
  </NavBarNav>
)

export default SocialLinks
