import React from "react"
import PropTypes from "prop-types"

import GlobalStyles from "../../styles/global"

import {
  Container,
  Main,
} from './styles'

import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children }) => {
  return (
    <Container>
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
