const links = [
  {
    label: 'Início',
    url: '/',
  },
  {
    label: 'Cursos',
    url: '/cursos',
  },
  {
    label: 'Pesquisar posts',
    url: '/search',
  },
]

export default links
