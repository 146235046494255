import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import { AvatarWrapper } from './styles'

const Avatar = () => {
  return (
    <AvatarWrapper>
      <StaticImage
        src="../../images/logo3.png"
        alt="Aluizio Developer"
        placeholder="blurred"
      />
    </AvatarWrapper>
    )
}

export default Avatar
