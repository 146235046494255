import {
  Github,
  Twitter,
  Youtube,
  Linkedin,
  DevTo
} from '@styled-icons/boxicons-logos'

const Icons = {
  Github,
  Twitter,
  Youtube,
  Linkedin,
  DevTo
}

export default Icons
