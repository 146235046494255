import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.section`
  display: flex;
`

export const Main = styled.main`
  width: 100%;
  margin-top: 5.5rem;
  margin-bottom: 3.5rem;
  min-height: 100%;

  ${media.lessThan('large')`
    margin-top: 6.5rem;
    margin-bottom: 4rem;
  `}
`
